import './App.scss';
import { useState } from 'react';
import Button from './components/button/button';
import fetchPlus from './utils/fetchPlus';

function App(settings: any, kioskMode: boolean) {
  const [data, setData] = useState<any | null>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [queueNumberVisible, setQueueNumberVisible] = useState(false);
  // const [showDataLink, setShowDataLink] = useState(true);
  const apiUrl = process.env.REACT_APP_apiBaseUrl ?? '';
  const apiKey = process.env.REACT_APP_apiKey;
  const onGetQueueNumberClick = async () => {
    setIsSubmitting(true);

    try {
      const response = await fetchPlus(apiUrl, {
        headers: {
          'X-QueueClientId': settings.appSettings.clientId,
          'x-functions-key': apiKey,
        },
      }, { timeoutInMilliseconds: 8000, tries: 4 });
      if (response && response !== null) {
        setData(response);
        setQueueNumberVisible(true);
        setIsSubmitting(false);
        // setShowDataLink(true);

        // if (kioskMode) {
        //   setShowDataLink(false);
        //   setTimeout(() => {
        //     window.location.reload();
        //   }, 4000);
        // }
      }
      else {
        setShowErrorMessage(true);
      }
    } catch (error) {
      console.log('Time out', error);
      setShowErrorMessage(true);
    }
  }

  // Render generic section about nummerlappen if the settings are not defined
  if ((!settings.appSettings.logoUrl || settings.appSettings.logoUrl !== '') && !settings.appSettings.clientId) {
    return (
      <p>Hej</p>
    )
  }

  const logoUrl = process.env.PUBLIC_URL + settings.appSettings.logoUrl;
  return (
    <div className="App">
      {
        settings.appSettings.logoUrl && settings.appSettings.logoUrl !== '' &&
        <div className='logoContainer'>
          <img src={logoUrl} className="logo" alt="logo" style={{cursor: 'pointer'}} onClick={() => window.location.reload()} />
        </div>
      }
      {
        showErrorMessage &&
        <div className='appContent' style={{ padding: '12px' }}>
          <p style={{ fontWeight: 700 }}>
            Något gick fel, var god ladda om sidan och prova igen.
          </p>
          <Button text='Ladda om sidan' onClick={() => window.location.reload()} />
        </div>
      }
      {
        !showErrorMessage &&
        <section>
          <div className='appContent'>
            {!queueNumberVisible &&
              <div>
                <Button text='Ta könummer' onClick={onGetQueueNumberClick} isSubmitting={isSubmitting} />
              </div>
            }
            {(queueNumberVisible && data) &&
              <>
                <div className="circle">
                  <svg width={400} height={400} className='queueNumberContainer' version="1.1" viewBox="0 0 500 500" preserveAspectRatio="xMinYMin meet">
                    <g>
                      <circle cx="250" cy="250" r="200" strokeWidth="24" stroke="#77d4fc" fill="transparent" />
                      <text x="50%" y="40%" textAnchor="middle" stroke="#888" fontSize="2.8rem">Ditt nummer är</text>
                      <text className='circleText' x="50%" y="67%" textAnchor='middle' stroke="#000" strokeWidth="4.5px" fontSize="8rem">
                        {data.latestCreatedQueueNumber.toString()}
                      </text>
                    </g>
                  </svg>
                </div>
                {data.showLink &&
                  <div className='extraInfoContainer'>
                    <h2 className='extraInfoHeading'>{data.linkHeading}</h2>
                    <p className='introText'>{data.linkIntroText}</p>
                    <a href={data.link}>{data.linkText}</a>
                  </div>
                }
              </>
            }
          </div>
        </section>
      }
    </div>
  );
}

export default App;
