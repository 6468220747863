/* eslint-disable */
async function fetchPlus(
    url,
    options,
    { timeoutInMilliseconds, tries },
) {
    let response;
    let controller;

    for (let n = 0; n < tries; n++) {
        let timeoutID;

        try {
            controller = new AbortController();

            timeoutID = setTimeout(() => {
                controller.abort(); // break current loop
            }, timeoutInMilliseconds);

            response = await fetch(
                url,
                { ...options, signal: controller.signal },
            ).then((data) => data.json());

            clearTimeout(timeoutID);
            return response;
        } catch (error) {
            if (timeoutID) {
                clearTimeout(timeoutID);
            }

            if (!(error instanceof DOMException)) {
                // Only catch abort exceptions here. All the others must be handled outside this function.
                throw error;
            }
        }
    }

    // None of the tries finished in time.
    throw new Error(
        `Request timed out (tried it ${tries} times, but none finished within ${timeoutInMilliseconds} millisecond(ms)).`
    );
}

export default fetchPlus;