import './App.scss';
import { useEffect, useState } from 'react';
import Button from './components/button/button';
import fetchPlus from './utils/fetchPlus';
import { ReactComponent as Loader } from './components/button/spinner.svg'

function CurrentNumberUI(settings: any, kioskMode: boolean) {
    const [data, setData] = useState<any | null>(null);
    const [showErrorMessage, setShowErrorMessage] = useState(false);
    const [queueNumberVisible, setQueueNumberVisible] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const apiUrl = `${process.env.REACT_APP_apiBaseUrl}?ping=ping` ?? '';
    const apiKey = process.env.REACT_APP_apiKey;

    const getLocalTimeFromUtc = (utcDateString: string) => {
        const utcDate = new Date(utcDateString);

        // Display Local Time in sv-SE
        const localTimeString = utcDate.toLocaleString("sv-SE");
        return localTimeString;
    }

    useEffect(() => {
        const onGetQueueNumberClick = async () => {
            setIsSubmitting(true);

            try {
                const response = await fetchPlus(apiUrl, {
                    headers: {
                        'X-QueueClientId': settings.appSettings.clientId,
                        'x-functions-key': apiKey,
                    },
                }, { timeoutInMilliseconds: 8000, tries: 4 });
                if (response && response !== null) {
                    setData(response);
                    setQueueNumberVisible(true);
                    setIsSubmitting(false);
                }
                else {
                    setShowErrorMessage(true);
                }
            } catch (error) {
                console.log('Time out', error);
                setShowErrorMessage(true);
            }
        }
        onGetQueueNumberClick();

    }, [apiUrl, apiKey, settings.appSettings]);

    const logoUrl = process.env.PUBLIC_URL + settings.appSettings.logoUrl;
    return (
        <div className="App">
            {
                settings.appSettings.logoUrl && settings.appSettings.logoUrl !== '' &&
                <div className='logoContainer'>
                    <img src={logoUrl} className="logo" alt="logo" style={{ cursor: 'pointer' }} onClick={() => window.location.reload()} />
                </div>
            }
            {
                showErrorMessage &&
                <div className='appContent' style={{ padding: '12px' }}>
                    <p style={{ fontWeight: 700 }}>
                        Något gick fel, var god ladda om sidan och prova igen.
                    </p>
                    <Button text='Ladda om sidan' onClick={() => window.location.reload()} />
                </div>
            }
            {
                !showErrorMessage &&
                <section>
                    <div className='appContent'>
                        {isSubmitting &&
                        <div style={{background: '#77d4fc', color: '#fff', padding: '12px'}}>
                            <p><strong>Hämtar senaste nummerlappen...</strong>
                            <Loader className='spinner' style={{marginLeft: '12px', verticalAlign: 'bottom', stroke: '#000'}} /></p>                            
                        </div>
                        }
                        {(queueNumberVisible && data) &&
                            <>
                                <div style={{margin: '0 auto', textAlign: 'center'}}>
                                    <h2 className='extraInfoHeading'>Senast tagna nummerlapp</h2>
                                    <p style={{ margin: 0, fontSize: '7rem' }}>{data.latestCreatedQueueNumber?.toString()}</p>
                                    <p style={{ margin: 0}}>
                                        <sub style={{margin: 0}}>
                                            Nummerlappen genererades
                                        </sub>                                        
                                    </p>
                                    <p style={{ margin: 0}}>
                                        <sub style={{margin: 0}}>
                                            {getLocalTimeFromUtc(data.lastModified)}
                                        </sub>                                        
                                    </p>
                                    <div style={{marginTop: '60px'}}>
                                    <Button text='Uppdatera' onClick={() => window.location.reload()} />
                                    </div>
                                </div>
                            </>
                        }
                    </div>
                </section>
            }
        </div>
    );
}

export default CurrentNumberUI;
