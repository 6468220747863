import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Clients } from './clients/clients';
import CurrentNumberUI from './currentNumberUI'

const currentPathName = window.location.pathname;
// const appSetting = Clients.find(({ pathName }) => currentPathName.toLowerCase() === pathName.toLowerCase());
const appSetting = Clients[0]; // Temp solution as we only have one item in the array now

const searchParams = new URLSearchParams(window.location.search);
const kioskMode = searchParams.get("kioskmode") === "true";

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const renderCurrentNumberUI = currentPathName.toLowerCase() === appSetting.currentNumberScreen.toLowerCase();
if (renderCurrentNumberUI) {
  root.render(
    <React.StrictMode>
      <CurrentNumberUI appSettings={appSetting ?? {}} />
    </React.StrictMode>
  );
}
else {
  root.render(
    <React.StrictMode>
      <App appSettings={appSetting ?? {}} kioskMode={kioskMode} />
    </React.StrictMode>
  );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
