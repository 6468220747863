import cx from 'classnames';
import { FunctionComponent } from 'react';
import styles from './button.module.scss';
import { ReactComponent as Loader } from './spinner.svg'

type Props = {
    disabled?: boolean;
    extraClass?: string;
    isSecondary?: boolean;
    isSubmitting?: boolean;
    onClick?: () => void;
    text: string;
}

const Button: FunctionComponent<Props> = ({ disabled = false, extraClass, isSecondary = false, isSubmitting = false, onClick = undefined, text }) => {
    const className = cx([styles.btn, { [styles.secondary]: isSecondary, [styles.loading]: isSubmitting, [styles[extraClass ?? 'none']]: extraClass ? true : false }]);

	if (disabled || isSubmitting) {
		return <button className={className} type="button" disabled>{text}<Loader className={styles.spinner} style={{marginLeft: '12px', verticalAlign: 'bottom'}} /></button>
	}

    return <button className={className} type="button" onClick={onClick}>{text}</button>
}

export default Button